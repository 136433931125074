import _ from 'underscore'
import $ from 'jquery'
import Backbone from 'backbone'
import {
    formatNote,
    formatPhone,
} from 'util/twistle'
import Contact from 'app/models/contact'

import { renderTemplate } from 'util/template_renderer'

App.InviteCodeSignupView = Backbone.View.extend({
    className:"invite_signup_view",
    events: {
        'click .invite_code_signup_submit_button': 'submitInviteCodeSignupForm',
        'click .self_enrollment_splash button': 'selfEnrollContinue',
    },
    warningFadeTimeout:35000,
    initialize: function() {
        var self = this;
        self.step = "common";
        _.bindAll.apply(_, [self].concat(_.functions(self)));
        self.render();

    },

    render: function() {
        let self = this;
        _.bindAll.apply(_, [this].concat(_.functions(this)));

        const inviteCodeContact = new Contact(App.config.get("inviteCodeUser")).toJSON();
        let shouldSkipInvitePage;

        App.config.set("inviteCodeContact", inviteCodeContact);

        self.options.clientRelationName = "client";

        _.each(inviteCodeContact.orgs,function(org){
            if (org.has_clients){
                self.options.clientRelationName = "patient";
            }
            if (org.id === inviteCodeContact.default_org || inviteCodeContact.orgs.length === 1){
                org.address = formatNote(org.address);
                org.phone = formatPhone(org.phone);
                inviteCodeContact.organization = org;

                if(org.allow_self_enrollment)
                {
                    App.config.set("provisionalPrimaryRoles", "p");
                    App.config.set("inviteCodeOrg", org);

                    // if props are set, use this view as a "splash" screen.
                    if(org.self_enrollment_props?.custom_logo_url){
                        $(".login_header").attr("style", `background-image:url(${org.self_enrollment_props.custom_logo_url})`);
                        $(".login_header").addClass("has_custom_header");
                    }

                    if(org.self_enrollment_props?.splash_content)
                    {
                        self.options.selfEnrollOrg = org;

                    }
                    else
                    {
                        // redirect to registration (needs a delay to prevent rendering race)
                        setTimeout(function(){
                            App.trigger("app:start_account_setup",{
                                inviteRelationship:"care provider",
                                isFromInviteCode:true,
                                invite_organization:self.options.selfEnrollOrg
                            });
                        },0);
                        shouldSkipInvitePage = true;
                    }
                }
            }
        });

        if(shouldSkipInvitePage)
        {
            // don't finish rendering - we are jumping right to registration
            return;
        }

        // if this is a self-enroll org invite,
        inviteCodeContact.profilefullimageurl = "/account/ViewPicture?size=std&pubuser=" + inviteCodeContact.username;

        let paramData = _.extend(self.options, {inviteCodeContact:inviteCodeContact});
        self.$el.html(renderTemplate('invite_code_signup_view', paramData));
        self.$(".invite_relationship").hoverClass("hovered");
        setTimeout(function(){
            self.postRender();
        }, 0);
        return self;
    },
    postRender: function(){
        var self = this;
    },

    submitInviteCodeSignupForm: function(evt){
        var self = this;
        evt.preventDefault();
        var inviteContact = App.config.get("inviteCodeContact");
        App.config.set("provisionalPrimaryRoles", "p");
        App.trigger("app:start_account_setup", {
            inviteRelationship: "care provider",
            isFromInviteCode: true,
            invite_organization: inviteContact.organization
        });
    },

    selfEnrollContinue:function(){
        var self = this;
        App.config.set("provisionalPrimaryRoles", "p");
        App.trigger("app:start_account_setup",{
            inviteRelationship:"care provider",
            isFromInviteCode:true,
            invite_organization:self.options.selfEnrollOrg
        });
    }
});
