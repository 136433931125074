import _ from 'underscore'
import $ from 'jquery'
import Backbone from 'backbone'


import {
    formatDate,
    passwordStrength,
    postRenderImages,
} from 'util/twistle'

import { ajax } from 'util/ajax'
import { applyDateMask } from 'util/controls'
import { isBlank } from 'util/string'
import { renderTemplate } from 'util/template_renderer'

import {
    validateEmailAddress,
    validateField,
} from 'util/validators'
import Contact from 'app/models/contact'

import { Countries } from 'data/countries'
import InviteContentMixin from 'app/mixins/invite_content_mixin'
import InviteMultipleHelperView from 'app/views/invite_multiple_helper_view'

const Mustache = require("mustache");

App.AccountSetupView = Backbone.View.extend({
    className:"account_setup_view",
    events: {
        'keyup input[name="password"]': 'passwordStrength',
        'click .signup_submit_button': 'submitCommonForm',
        'click .validate_email_button': 'submitWithEmailOrPhoneVerification',
        'click .profile_submit_button': 'afterProfilePictureForm',
        'submit .profilepic_form' : 'afterProfilePictureForm',
        'click .terms_of_use_submit_button': 'termsOfUseAccepted',
        'click .terms': 'showTermsOfUse',
        'blur  input[name="email"]': 'emailChanged',
        'click .create_org_button': 'createOrg',
        'click .create_org_skip_button': 'skipCreateOrg',
        'click .registration_complete_button': 'registrationComplete',
        'keyup .organizationname': 'typingOrgName',
        "click .submit_multiple_invites": "submitMultipleInvites",
        "click .cancel_multiple_invites": "afterMultipleInvites",
        'keyup .invite_multiple input': "inputProvided",
        'click .specialty': 'specialtySelected',
        'click .credentials': 'credentialsSelected',
        'click .signup_login_button': 'loginClicked',
        'click .more_invite_connection_message': 'inviteConnectionMessageMoreClicked',
        'click .iphone_app_download_button': 'iPhoneAppClick',
        'click .self_enrollment_connect_user_wrap button': 'connectExistingUserToSelfEnrollment',
        'submit form': 'cancelDefaultFormSubmit'
    },
    WARNING_FADE_TIMEOUT:35000,
    TRUNCATED_SUMMARY_LENGTH: 100,
    initialize: function() {
        var self = this;
        self.step = "common";
        self.options.currentUILanguage = "en";
        _.bindAll.apply(_, [self].concat(_.functions(self)));
        _.extend(self, InviteContentMixin);
        if(App.config.get("provisionalUsername")){
            // we are logged in as a provisional user - lets get their account details
            App.account.set("username", App.config.get("provisionalUsername"));
            App.account.fetch({
                success:function(){
                    self.options.currentUILanguage = App.account.get("locale") || "en";
                    self.render();
                },
                error: function(_, response){
                    if (response?.responseJSON?.error === "NeedsVerification") {
                        window.location = "/account/confirm"
                    }
                }
            });
        }
        else
        {
            if(!self.options.invite_organization && App.config.get("inviteCodeOrg"))
            {
                self.options.invite_organization = App.config.get("inviteCodeOrg");
            }

            if(self.options.invite_organization)
            {
                self.options.self_enrollment_props = self.options.invite_organization.self_enrollment_props || {};
            }

            if(App.account && !App.account.get("provisional") &&
                self.options.invite_organization.allow_self_enrollment)
            {
                // full user is logged in but followed invite URL
                self.showConnectExistingUserToSelfEnrollment();
            }
            else{
                self.render();
            }

        }
        App.bind("app:webcam_shown", self.webCamShown, self);
        App.bind("app:webcam_closed", self.webCamClosed, self);
    },

    render: function() {
        var self = this;
        _.bindAll.apply(_, [this].concat(_.functions(this)));
        if(self.step === "registration_completed") {
            self.$el.html(renderTemplate("registration_completed_template", self.options, self.options.currentUILanguage));
        }
        else if(self.step === "profilepicture") {
            self.renderProfilePicture();
        }
        else if ( self.step === 'orgsetup') {
            self.renderOrgSetup();
        }
        else if ( self.step === "multiple_invites") {
            self.renderPeople();
        }
        else if ( self.step === "verify_email_or_phone") {
            self.renderValidateEmailOrPhone();
        }
        else if ( self.step === "self_enroll_workflow") {
            self.renderSelfEnrollWorkflow();
        }
        else if ( self.step === "anonymous_intake_form") {
            self.renderAnonymousIntakeFormSubmission();
        }
        else {
            if(App.config.get("provisionalInvitedBy")){
                self.originator_contact = new Contact({username:App.config.get("provisionalInvitedBy")});
                self.originator_contact.fetch({
                                  success:function(){self.renderCommonForm(self.originator_contact.toJSON());},
                                  error:function(s){
                                        self.$el.html("<h2>Unfortunately the invite you received is no longer valid.</h2>");
                                  }});
            }
            else{
                self.renderCommonForm();
            }
        }
        return self;
    },

    cancelDefaultFormSubmit: function(e){
        e.preventDefault();
    },

    renderCommonForm: function(inviteContact){
        var self = this;

        self.options.primaryroles = App.config.get("provisionalPrimaryRoles") || App.account.get("primaryroles") || "";
        self.options.isProvider = (self.options.primaryroles.indexOf("c") > -1);
        self.options.capturePersonalInfo = self.options.primaryroles.indexOf("p") > -1;

        // if the provisional acct has a phone, then we only collect phone, otherwise email
        self.options.phoneRegistration = false;

        if(App.account.get("phone"))
        {
            self.options.phoneRegistration = App.account.get("phone").length > 0;
        }

        // find the self relationship, extract relevant info
        if(inviteContact) {
            // Fill in invite details
            self.options.inviteContact = inviteContact;
            self.options.invite_originator = inviteContact.username; // keep this one separate
            _.extend(self.options, self.originator_contact.getInviteInfo());
            self.options.invite_organization = self.options.invite_organization || inviteContact.organization;
        } else {
            self.options.inviteContact = false;
            self.options.invite_originator = false;
            self.options.invite_relationship = undefined;
            self.options.invite_is_custodian = false;
            self.options.invite_name = "";
        }

        if(self.options.invite_organization && self.options.invite_organization.allow_self_enrollment)
        {
            self.options.isSelfEnrollment = true;
            self.options.phoneRegistration = !self.options.self_enrollment_props.email_registration;
            self.options.capturePersonalInfo = self.options.self_enrollment_props.capture_personal_info;
            self.options.skipShowAllFieldsRequired = self.options.self_enrollment_props.skip_show_all_fields_required;
            self.options.skipShowMobilePhoneInfo = self.options.self_enrollment_props.skip_show_mobile_phone_info;
            self.options.skipName = !self.options.self_enrollment_props.capture_name;
            self.options.skipLanguage = !self.options.self_enrollment_props.capture_language;
            self.options.skipPassword = !self.options.self_enrollment_props.capture_password;
            self.options.skipAlternateLogin = self.options.self_enrollment_props.skip_already_on_twistle_login;
            self.options.signUpHeaderText = self.options.self_enrollment_props.signup_header_text;
            self.options.skipTermsOfUse = self.options.self_enrollment_props.skip_terms_of_use;
            self.options.signupButtonText = self.options.self_enrollment_props.signup_button_text;
            self.options.skipPhoneEmailVerification = self.options.self_enrollment_props.skip_verification;
            self.options.anonymousIntakeSubmissionsMode = self.options.self_enrollment_props.anonymous_intake_submission_mode;
            if(self.options.anonymousIntakeSubmissionsMode){
                // just move right into capturing the intake form, no registration
                self.step = "anonymous_intake_form";
                self.render();
                return;
            }
        }

        self.options.clientName = "Patient";

        self.options.orgMemberInvite = self.options.invite_relationship === "org-member";

        if ( App.config.get("isMobile")  ) {
            self.options.isMobile = true;
        }

        self.options.countries = Countries;
        self.options.availableLanguages = _.values(App.config.get("availableLanguages"));

        self.$el.html(renderTemplate("registration_common_template", self.options, self.options.currentUILanguage));

        self.$(".twistle_dropdown").twistle_dropdown({addHiddenField:true, itemselected:self.changeUILanguage});

        const dob = self.$("#dob")[0]
        if (dob) {
            applyDateMask(dob);
        }

        self.$("input[name=email]").val(App.account.get("email"));
        self.$("input[name=phone]").val(App.account.get("phone"));

        if(!self.options.invite_is_custodian){
            self.$("input[name=firstname]").val(App.account.get("firstname"));
            self.$("input[name=lastname]").val(App.account.get("lastname"));
        }
        else{
            self.$("input[name=custodee_account_first_name],input[name=custodee_account_last_name]").autoCapField();

            self.$("input[name=custodee_account_first_name]").val(App.account.get("firstname"));
            self.$("input[name=custodee_account_last_name]").val(App.account.get("lastname"));
        }

        // turn on auto cap for first / last name fields
        self.$("input[name=firstname],input[name=lastname]").autoCapField();

        self.$(".others_only").hide();

        self.$(".twistle_dropdown").twistle_dropdown({addHiddenField:true});

        self.setOriginatorMessage();
        self.updateCommonFieldsWithSavedState();
        postRenderImages(self.$el);
    },

    changeUILanguage: function(){
        var self = this;
        var oldLocale = self.options.currentUILanguage;
        var newLocale = self.$(".twistle_dropdown.select_language_dropdown").twistle_dropdown("getValue").value;
        if (oldLocale != newLocale) {

            self.saveCommonFieldState();

            self.options.currentUILanguage = newLocale;
            App.account.attributes.locale = newLocale;

            self.render();

        }
    },

    inviteConnectionMessageMoreClicked: function(){
        var self = this,
            notes = self.options.inviteConversation.get("notes"),
            messageUi = self.$(".originator_message"),
            summary = (notes[0] && notes[0].summary) || false;

        messageUi.find(".more_invite_connection_message").fadeOut("fast", function(){
            messageUi.html(summary);
        });


    },
    emailChanged: function(){
        var self = this;
        var emailInput = this.$("input[name=email]");
        var newEmail = emailInput.val();
        var currentEmail = self.options.newEmail || App.config.get("provisionalEmail");

        if(self.options.isSelfEnrollment){
            // don't do realtime elgibility checks in self-enroll mode
            return;
        }

        if(newEmail !== currentEmail){
            if(!validateEmailAddress(newEmail)){
                emailInput.addClass("form_error");
                self.$(".signup_processing_message_area").stop().css("opacity", 1).show().text("You must enter a valid email address").fadeOut(self.WARNING_FADE_TIMEOUT);
                return;
            }
            var params = {email:newEmail};

            ajax.request("/account/CheckEmailAvailability", params, [], function(resp){
                if(resp.result){
                    self.options.newEmail = newEmail;
                    self.options.isDuplicateEmail = false;
                    emailInput.removeClass("form_error");
                    self.$(".signup_processing_message_area").hide();
                }
                else{
                    self.options.newEmail = false;
                    self.options.isDuplicateEmail = true;
                    emailInput.addClass("form_error");
                    self.$(".signup_processing_message_area").show().text("The email address you specified is already in use on Twistle");
                }
            },true);
        }
    },

    saveCommonFieldState: function()
    {
        var self=this, params = self.getValidationParams();
        self.processParamValidations(params, {}, [], true); // extracts field values but doesn't show validations
        self._commonFieldState = params;
    },

    updateCommonFieldsWithSavedState: function()
    {
        var self = this;
        if(self._commonFieldState)
        {
            _.each(self._commonFieldState, function (param)
            {
                var input = self.$(param.altFieldSelector || "input[name=" + param.field + "]");
                if(input && input.val && param.value)
                {
                    input.val(param.value);
                }

                if(param.isRadio && param.value)
                {
                    self.$("input[name=" + param.field + "]").each(function(idx, elem)
                    {
                        var $elem = $(elem);
                        if($elem.val() == param.value)
                        {
                            $elem.prop("checked", true);
                        }
                    });
                }
            });
        }
    },

    processParamValidations: function (params, validatedParams, errors, hideErrors) {
        var self = this;

        _.each(params, function(param){
            var input = self.$(param.altFieldSelector || "input[name=" + param.field + "]");
            var ui = (param.altSelector && self.$(param.altSelector)) || input;
            if ( param.value === undefined ) {
                param.value = (param.altFieldSelector) ? input.val() : (input.val() || "");
            }

            if(!hideErrors)
            {
                if (validateField(param.value, param.validator, param.params))
                {
                    ui.removeClass("form_error");
                    validatedParams[param.field] = (param.postFilter) ? param.value.replace(param.postFilter, '') : (param.value || "");
                }
                else
                {
                    ui.addClass("form_error");
                    errors.push(param.message || "Please fill out all required fields");
                }
            }
        });
    },

    passwordStrength: function(evt){
        var self = this;
        var currentPwd = self.$("input[name=password]").val();
        if(currentPwd.length > 0){
            var strength = passwordStrength(currentPwd);
            self.$(".password_message").alterClass("pw_strength_*", "pw_strength_"+strength.value);
            self.$(".password_message").html(strength.message);
        }
        else{
            self.$(".password_message").html("").alterClass("pw_strength_*");
        }

    },

    specialtySelected: function() {
    },

    credentialsSelected: function() {
        var self = this;
        // get the value of the credentials

        const credential = self.$("input:radio[name=credentials]:checked").val();

        if ( credential === "MD" || credential === "DO") {
            self.$(".doctors_only").show();
            self.$(".others_only").hide();
        }
        else {
            self.$(".doctors_only").hide();
            self.$(".others_only").show();
        }
    },

    getValidationParams: function()
    {
        var self = this, validationParams = [];

        if(self.options.phoneRegistration)
        {
            validationParams.push({field:"phone",validator:"phone",message:"Please enter a valid 10-digit phone number", postFilter:/[\-\(\)\. ]/g});
        }
        else
        {
            validationParams.push({field:"email",validator:"email",message:"You must enter a valid email address"});
        }

        if(!self.options.skipName)
        {
            validationParams = validationParams.concat([
                                {field:"firstname"},
                                {field:"lastname"}
            ]);
        }
        if(!self.options.skipPassword)
        {
            validationParams = validationParams.concat([
                {field:"password",validator:"passwordStrength",message:"Your password isn't strong enough."}
            ]);
            if (! App.config.get("isMobile") ) {
                validationParams.push( {field:"cpassword",validator:"passwordMatch", params:{password:self.$("input[name=password]").val()},
                    message:"Your password(s) do not match."} );
            }
        }

        if(self.options.capturePersonalInfo && !self.options.invite_is_custodian){
            validationParams.push({field:"dob", validator:"date", message:"Please enter your birthdate in the format mm/dd/yyyy."});
            validationParams.push({field:"dob", validator:"age", params:{minAge: App.account.orgMinimumAge()},
                                      message:"Unfortunately, Twistle can only be used by individuals older than age " + App.account.orgMinimumAge() + "."});
            validationParams.push({field:"gender", altSelector:".gender_input_area", altFieldSelector: "input[name=gender]:checked", isRadio:true});
        }

        if(self.options.invite_is_custodian){
            validationParams.push({field:"custodee_account_first_name"});
            validationParams.push({field:"custodee_account_last_name"});
            validationParams.push({field:"custodee_account_relationship", altSelector:".custodee_account_relationship"});
            validationParams.push({field:"custodee_account_dob", validator:"date", message:"Please enter the " + self.options.clientName + "'s birthdate in the format mm/dd/yyyy."});
        }

        return validationParams;
    },

    submitCommonForm: function(evt) {
        var self = this;
        evt.preventDefault();
        var errors = [], registerParams = {}, needsVerification = false,
            verificationUrl = "", verificationParams = {};

        var country = self.$(".twistle_dropdown.country").twistle_dropdown("getValue").value;

        var validationParams = self.getValidationParams();

        self.processParamValidations(validationParams, registerParams, errors);

        if(self.options.skipName)
        {
            registerParams["firstname"] = "Registered";
            registerParams["lastname"] = "Account";
        }

        if ($.inArray(self.options.currentUILanguage, self.options.availableLanguages)) {
            registerParams.locale = self.options.currentUILanguage;
        }

        // check to see that the current email they've got isn't a duplicate of someone else's
        if (self.options.isDuplicateEmail){
            errors.push("The email address you specified is already in use on Twistle");
            self.$("input[name=email]").addClass("form_error");
        }

        if(errors.length > 0){
            self.$(".signup_processing_message_area").show().text(errors[0]);
            return;
        }

        if (self.options.isFromInviteCode && App.config.get("inviteCodeContact")){
            registerParams.invite_originator = App.config.get("inviteCodeContact").id;
            registerParams.invite_relationship = self.options.inviteRelationship;
            registerParams.invite_organization_id = self.options.inviteOrgId;
        }

        self.options.registerParams = registerParams;

        // do we need to validate email or phone?
        if(self.options.isSelfEnrollment && self.options.skipPhoneEmailVerification){
            needsVerification = false;
        }
        else if(self.options.phoneRegistration && App.account.get("rawPhone") !== self.options.registerParams.phone)
        {

            needsVerification = true;
            verificationUrl = '/account/AddUnverifiedPhone';
            verificationParams = {phone:self.options.registerParams.phone};
        }
        else if(!self.options.phoneRegistration && App.account.get("email") && (App.account.get("email") !== self.options.registerParams.email))
        {
            needsVerification = true;
            verificationUrl = '/account/AddUnverifiedEmail';
            verificationParams = {email:self.options.registerParams.email};
        }
        else if(self.options.isFromInviteCode)
        {
            needsVerification = true;
            verificationUrl = self.options.isSelfEnrollment ? '/account/AddUnverifiedEmail' : '/account/RequestEmailVerification';
            verificationParams = {email:self.options.registerParams.email};
        }

        if(needsVerification)
        {
            ajax.request(verificationUrl, verificationParams, [], function(resp)
            {
                self.step = "verify_email_or_phone";
                self.render();
            }, true, self.registerFailure, self.$(".signup_submit_button"));
        }
        else
        {
            ajax.request('/account/RegisterAccount',
                         registerParams, [], self.registerSuccess, true, self.registerFailure, self.$(".signup_submit_button"));
        }
    },

    registerSuccess: function(response) {
        var self = this;

        if (self.options.isFromInviteCode && App.config.get("inviteCodeContact")){
            // ok - we successfully pre-registered and now we have an account.
            // If we aren't in open enrollment mode, Lets log in as them.
            if(!self.options.isSelfEnrollment)
            {
                App.logClickstream("INVITE_CODE_REGISTRATION_REQUESTED");
                ajax.request('/account/Login',{username:self.options.registerParams.email,password:self.options.registerParams.password},[],
                        function(resp){
                            self.startProfilePictureForm();
                            App.trigger("app:user_did_login");
                        }, true, this.registerFailure, self.$(".signup_submit_button"));
            }
            else
            {
                // because otherwise they *do* get logged in as their newly registered account
                // so we can now refresh their "real" account
                App.account.set("username", response.username);

                // just in case fetching the account is slow
                self.$(".signup_submit_button").addClass("submit_processing")
                                                       .prop("disabled", true)
                                                       .spin({left: -14, length: 3, radius: 1, lines: 6, width: 1.5, color: "#888"});
                App.account.fetch({
                    success:function(){
                        // does self enrollment want avatar(s)
                        if(self.options.self_enrollment_props.capture_avatar)
                        {
                            self.startProfilePictureForm();
                        }
                        else
                        {
                            self.startFinishSelfEnrollment();
                        }
                    },
                    error: function(_, response){
                        if (response.responseJSON.error === "NeedsVerification") {
                            window.location = "/account/confirm"
                        }
                    }
                });
            }
        }
        else{
            // send event to clickstream
            App.logClickstream("REGISTRATION_SUBMITTED");

            if (response.custodee){
                self.options.registerParams.custodee_username = response.custodee.username;
            }

            if (App.config.get("isMobile") ) {
                self.afterProfilePictureForm();
            } else {
                self.startProfilePictureForm();
            }
            self.render();
        }
    },

    registerFailure: function(response, errorObj) {
        var self = this;
        if (errorObj && errorObj.error && errorObj.error === "AlreadyRegistered"){
            App.trigger("app:navigate_to_app_desktop");
        }
        else{
            self.$(".signup_processing_message_area").stop().css("opacity", 1).show().text("Registration error: " + errorObj.text).fadeOut(30000);
        }
    },

    renderValidateEmailOrPhone: function()
    {
        var self = this;
        self.$el.html(renderTemplate("registration_validate_email_template", self.options, self.options.currentUILanguage));
    },

    submitWithEmailOrPhoneVerification: function()
    {
        var self = this;

        self.options.registerParams.email_or_phone_verification_code = self.$("input[name=email_validation_code]").val();

        if(!validateField(self.options.registerParams.email_or_phone_verification_code)){
            self.$(".email_validation_processing_message_area").show().text("You must enter a validation code!");
            return;
        }

        /* flow below:
            a) normal invite - submit verification code with rest of reg data
            b) invite URL invite - first verify email, THEN submit registration
            c) invite URL for self-enroll org - capture validation code, submit w/ registration
         */


        var submitRegistration = function(){
            // normal signup - submit registration
            ajax.request('/account/RegisterAccount',
                             self.options.registerParams, [], self.registerSuccess, true, self.emailValidationFailed, self.$(".validate_email_button"));
        };

        var submitVerification = function(url, params)
        {
            ajax.request(url, params, [], function(resp){
                submitRegistration();
            }, true, self.emailValidationFailed, self.$(".validate_email_button"));
        };

        var verifyUrl, params={};
        if(self.options.isFromInviteCode)
        {
            if(self.options.isSelfEnrollment)
            {
                submitRegistration();
            }
            else
            {
                verifyUrl = "/account/CheckEmailVerification";
                params.email = self.options.registerParams.email;
                params.code = self.options.registerParams.email_or_phone_verification_code;
                submitVerification(verifyUrl, params);
            }
        }
        else
        {
            submitRegistration();
        }

    },

    emailValidationFailed:function(resp, errorObj)
    {
        var self = this;
        self.$(".email_validation_processing_message_area").show().text(errorObj.text);

    },

    startProfilePictureForm: function() {
        var self = this;

        self.step = "profilepicture";
        self.render();

    },

    webCamShown: function(){
        var self = this;
        self.$(".profile_submit_button").hide();
        self.$(".picture_header_message").hide();

    },
    webCamClosed: function(){
        var self = this;
        self.$(".profile_submit_button").show();
        self.$(".picture_header_message").show();
    },

    afterProfilePictureForm: function(evt) {
        var self = this;

        if ( evt ) {
            evt.preventDefault();
        }

        // If we are in a self-enrollment mode, and we
        // have finished capturing a profile photo (i.e. *here*)
        // then we need to return to the next step in the signup
        // journey, which is starting to finish the self-enrollment
        // process.
        //
        // We are intentionally *not* calling render() in that circumstance
        // because startFinishSelfEnrollment() will take care of that for us.
        if (self.options.isSelfEnrollment) {
            self.startFinishSelfEnrollment();
        } else {
            self.step = "registration_completed";
            self.render();
        }
    },

    renderProfilePicture: function() {
        var self = this;

        self.$el.html(renderTemplate("registration_profilepic_template", self.options, self.options.currentUILanguage));

        var userDetails = {
            firstname: self.options.registerParams.firstname,
            lastname: self.options.registerParams.lastname,
            specialty: self.options.registerParams.specialty
        };

        userDetails.formalname = userDetails.firstname + " " + userDetails.lastname;


        self.photoUploadView = self.addChildView(new App.PhotoUploadView({
            setAsProfilePhoto: true,
            userDetails: userDetails,
            onPhotoComplete: self.photoAdded,
            currentPhotoUrl: App.config.get("baseUrl") + "/account/ViewPicture?size=prf&rnd" + new Date().getTime(),
            renderAsColumn: true,
        }));

        self.$(".signup_picture").html(self.photoUploadView.$el);

        if ( self.options.invite_is_custodian ) {

            var custodeeDetails = {
                firstname: self.options.registerParams.custodee_account_first_name,
                lastname: self.options.registerParams.custodee_account_last_name
            };

            custodeeDetails.formalname = custodeeDetails.firstname + " " + custodeeDetails.lastname;

            self.photoUploadViewCustodee = self.addChildView(new App.PhotoUploadView({
                setAsProfilePhoto: true,
                setAsProfilePhotoForUser: self.options.registerParams.custodee_username,
                userDetails: custodeeDetails,
                onPhotoComplete: self.photoAdded,
                renderAsColumn: true,
                photoUploadTitle: `Update the photo for ${custodeeDetails.firstname}`
            }));

            self.$(".signup_picture_custodee").html(self.photoUploadViewCustodee.$el);
        }

        if (!self.options.isFromInviteCode){
            App.router.navigate("setup/continue");
        }

        setTimeout(function(){
            $(document).scrollTo(0);
        },50);
    },

    photoAdded: function() {
        var self = this;
        self.$(".profile_submit_button").html("Continue");
    },

    renderOrgSetup: function() {
        var self = this;
        self.$el.html(renderTemplate("registration_orgsetup_template", self.options, self.options.currentUILanguage));
    },

    createOrg: function() {
        var self = this;
        var orgname = self.$(".organizationname").val();
        var hasclients = self.$(".hasclients:checked").val();

        var orgparams = {};

        if (isBlank(orgname)) {
            self.$(".signup_processing_message_area").show().text("Please provide an organization or practice name");
            return;
        }

        orgparams.org_name = orgname;
        orgparams.has_clients = hasclients;

        if (hasclients === "yes" ) {
            self.options.addmembertype = "Staff";
        } else {
            self.options.addmembertype =  "";
        }


        ajax.request('/account/CreateOrUpdateOrg',
                     orgparams, [],
                     function(resp){
                         self.options.new_org = resp.org;
                         self.afterCreateOrg();
                     },
                     true,
                     function(){
                        self.$(".signup_processing_message_area").stop().css("opacity", 1).show().text("Registration error - please check all fields!").fadeOut(10000);
                     },
                     self.$(".create_org_button"));



    },

    afterCreateOrg: function() {
        var self = this;
        if ( App.config.get("isMobile")) {
            self.step = "registration_completed";
        } else {
            self.step = "multiple_invites";
        }
        self.render();
    },

    skipCreateOrg: function() {
        var self = this;
        self.step = "registration_completed";
        self.render();
    },

    renderPeople: function() {
        var self = this;

        self.$el.html(renderTemplate("registration_multiple_invites_template", self.options, self.options.currentUILanguage));
        self.multiInviteOptions = {
            relationship:"org-member",
            organization_id:self.options.new_org.id||-1};
        self.inviteTable = self.addChildView(new InviteMultipleHelperView());

        self.inviteTable = self.addChildView(new InviteMultipleHelperView(self.multiInviteOptions));
        self.inviteTable.$el.insertAfter(self.$(".subheading"));
    },


    inputProvided: function() {
        var self = this;

        self.$('.submit_multiple_invites').removeClass("grayout");
        self.$('.cancel_multiple_invites').addClass("grayout");

    },

    submitMultipleInvites: function(evt){
        var self = this;

        var multipleInvites = self.inviteTable.getMultipleInvites(false, false);
        if (multipleInvites.errors){
            self.$(".signup_processing_message_area").stop().css("opacity", 1).show().text("All fields are required!").fadeOut(self.WARNING_FADE_TIMEOUT);
        }
        else{
            _.extend(multipleInvites.accounts, self.multiInviteOptions);
            // kinda someday do - message, share with network, visible_to_staff, etc fields that are relevant to non-org relationships

            ajax.request('/account/BulkInvite', multipleInvites.accounts, [],
                         self.submitMultipleInvitesSuccess, true, self.submitMultipleInvitesFailure,
                         self.$(".submit_multiple_invites"));

        }
    },

    submitMultipleInvitesSuccess: function(resp){
        var self = this;

        self.afterMultipleInvites();
    },

    submitMultipleInvitesFailure: function(resp){
        var self = this;
        self.$(".signup_processing_message_area").stop().css("opacity", 1).show().text("A server error occurred while adding members. Please check all fields!").fadeOut(self.WARNING_FADE_TIMEOUT);

    },


    afterMultipleInvites: function(){
        var self = this;
        self.step = "registration_completed";
        self.render();
    },

    typingOrgName: function() {
        var self =  this;
        self.$('.connect_clients').css('visibility', 'visible');
        self.$('.create_org_skip_button').addClass("grayout");
        self.$('.create_org_button').removeClass("grayout");
    },


    showTermsOfUse: function() {
        var self = this;
        window.open("/document/terms-of-use");
    },

    termsOfUseAccepted:function(){
        var self = this;
        ajax.request('/account/RegisterAccount', self.options.registerParams, [], this.registerSuccess, true, this.registerFailure, self.$(".terms_of_use_submit_button"));
    },

    registrationComplete: function(){
        App.trigger("app:navigate_to_app_desktop");
    },

    loginClicked: function(){
        App.trigger("app:reset_login_desktop");
    },

    iPhoneAppClick: function()
    {
        var pageUrl = App.config.get("iOSStoreUrl");
        window.open(pageUrl);
    },

    showConnectExistingUserToSelfEnrollment:function()
    {
        var self = this;
        let paramData = {
            existing_user_content: self.options.invite_organization.self_enrollment_props.existing_user_content,
            invite_contact: App.config.get("inviteCodeContact")
        };
        self.$el.html(renderTemplate('invite_self_enroll_connect_existing_user_template', paramData));
    },

    connectExistingUserToSelfEnrollment:function()
    {
        var self = this;

        ajax.request("/account/Invite", {
            invited_username: App.config.get("inviteCodeUser").username,
            relationship: "care provider",
            organization_id: self.options.invite_organization.id,
            accepted: true,
            from_invite_code: App.config.get("inviteCodeUser").invite_code
        }, [], function(){
            self.startFinishSelfEnrollment();
        });
    },

    startFinishSelfEnrollment:function()
    {
        var self = this;
        if(self.options.invite_organization && self.options.invite_organization.self_enroll_workflow)
        {
            self.step = "self_enroll_workflow";
            self.render();
        }
        else
        {
            self.step = "registration_completed";
            self.render();
        }
    },

    _getSelfEnrollWorkflowProps: function()
    {
        var self = this,
            workflowDef = new App.WorkflowDefinition(self.options.invite_organization.self_enroll_workflow).toJSON(),
            workflowProps = _.defaults(self.options.invite_organization.self_enrollment_props,
                                       workflowDef.extended_props.self_enrollment_props || {});
            return [workflowDef, workflowProps];
    },

    renderSelfEnrollWorkflow: function()
    {
        var self = this,
            workflowProps = self._getSelfEnrollWorkflowProps();

        self.$el.html(renderTemplate('invite_self_enroll_workflow_template', {
            workflowStarted: false,
            workflowProps: workflowProps[1]
        }));

        self.workflowStartExecutionView = App.openDialogView(new App.WorkflowStartExecutionDialogView({
                workflowDef: workflowProps[0],
                model: new Contact(App.account.toJSON()),
                el:self.$(".self_enroll_workflow_wrap"),
                selfEnrollMode: true,
                submitButtonText: workflowProps[1].start_workflow_button_text || "Continue",
                fullyPreventDialog: true
        }));
        App.bind("app:workflow_execution_started", self.postWorkflowExecuted, self);
    },

    renderAnonymousIntakeFormSubmission: function()
    {
        let self = this;
        let workflowProps = self._getSelfEnrollWorkflowProps();

        let tpl = renderTemplate('invite_self_enroll_workflow_template', {
            workflowStarted: false,
            workflowProps: workflowProps[1]
        });
        self.$el.html(tpl);

        self.anonymousFormSubmissionView = App.openDialogView(new App.FormSubmissionDialogView({
                formId: workflowProps[0].execute_on_submission_of_form_definition,
                anonymousSubmissionMode: true,
                fullyPreventDialog: true,
                el:self.$(".self_enroll_workflow_wrap"),
                submitButtonText: workflowProps[1].start_workflow_button_text || "Continue"
        }));
        App.bind("app:form_submitted", self.postAnonIntakeFormSubmitted, self);
    },

    postAnonIntakeFormSubmitted:function(form, submitResponse, convseq, responseToNoteId, fieldVals){
        var self = this,
            workflowProps = self._getSelfEnrollWorkflowProps()[1];
        App.unbind("app:form_submitted", self.postAnonIntakeFormSubmitted, self);
        // mustache whatever custom post anonymous intake submission content we have with the execution data
        try {
            workflowProps.post_anon_submission_content = Mustache.render(workflowProps.post_anon_submission_content, {
                form: form,
                submission: submitResponse,
                submissionDisplayDate: formatDate(submitResponse.moddate),
                dayOfWeekFlags: ["Mon","Tue","Wed","Thu","Fri","Sat","Sun"].reduce((o, key) => ({
                    ...o, [key]: formatDate(submitResponse.moddate, "{day}") === key
                }), {}),
                subf: form.fields.reduce((o, key) => {
                    var fieldVal = fieldVals[key.id];
                    if (fieldVal.value){
                        // If field is text box, set the value of the mustache variable to the field value
                        o[key.identifier] = fieldVal.value;
                    } else if (fieldVal.field_option_ids){
                        // If field is radio button/checkbox/dropdown,
                        // set the value of the mustache variable to option labels
                        // (comma separated if there are multiple)
                        var matchedOptionLabels = [];
                        fieldVal.field_option_ids.forEach((option, i)=>{
                            matchedOptionLabels[i] = key.options.find(y => y.id == option).label;
                         })
                         o[key.identifier] = matchedOptionLabels.join(", ");
                    }
                    return o;
                }, {})
            });
        } catch(e){
          // template failed, nbd
        }

        // rerender w/ post-anon_submission content
        self.$el.html(renderTemplate('invite_self_enroll_workflow_template', {
            workflowStarted: true,
            workflowProps: workflowProps
        }));
    },

    postWorkflowExecuted:function(workflowExecutionId, workflowExecution){
        var self = this,
            workflowProps = self._getSelfEnrollWorkflowProps()[1];

        self.workflowStartExecutionView.close();
        App.unbind("app:workflow_execution_started", self.postWorkflowExecuted, self);
        if(self.options.self_enrollment_props.redirect_to_app)
        {
            App.trigger("app:navigate_to_app_desktop");
        }
        else
        {
            // mustache whatever custom post workflow content we have with the execution data
            try {
                workflowProps.post_workflow_content = Mustache.render(workflowProps.post_workflow_content, {
                    workflowExecution: new App.WorkflowExecution(workflowExecution).toJSON()
                });
            } catch(e){
              // template failed, nbd
            }

            // rerender w/ post-workflow content
            self.$el.html(renderTemplate('invite_self_enroll_workflow_template', {
                workflowStarted: true,
                workflowProps: workflowProps
            }));

            // add mobile app content
            if(self.options.self_enrollment_props.show_native_app_info) {
                App.bind("app:cancel_native_app_load", function () {
                    App.trigger("app:navigate_to_app_desktop");
                });
                var appInstallView = App.openDialogView(new App.MobileAppInstallView({
                    isRegistration: true,
                    hideInvitedBy: true,
                    el: self.$(".app_install_wrap")
                }));
            }
        }
    }

});
